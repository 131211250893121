@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rubik-font {
  font-family: "Rubik", sans-serif;
}

.inter-font {
  font-family: "Inter", sans-serif;
}

.spanbold span{
  font-weight: 600;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button{
  display: flex;
  width: 170px;
  height: 45.784px;
  padding: 10px 35px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #40DDB6; 
}
.spancons span {
  color: #40DDB6;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-mainn{
  background: url(./assets/img/main.jpg) lightgray 50% / cover no-repeat;
} 
.blockmain{
  background-color: rgba(55, 57, 63, 0.91);
  backdrop-filter: blur(9.5px);
}
.swiper-perss > .swiper-button-next, .swiper-button-prev {
  background-color: #CF2D10;
  padding: 30px;
  border-radius: 15px;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 25px!important;
  color: white;
  font-weight: 700;
}

.swiper-slide-prev,
.swiper-slide-next {
  filter: blur(5px);
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  background-color: transparent!important;
  border: none!important;
  color: #fff!important;
  text-transform: uppercase!important;
  padding-right:25px!important;

  
}
.Dropdown-menu {
  font-style: normal;
  font-size: 16px!important;
  max-height: 300px!important;
  
}
.Dropdown-arrow {
  border-color: #fff transparent transparent!important;
  margin-top: 3px;
 
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #fff!important;
}