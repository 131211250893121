@import '../../styles/var';

.form {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background:  radial-gradient(228% 117.58% at 24.99% 43.36%, rgba(231, 226, 221, 0.40) 0%, rgba(116, 103, 96, 0.40) 100%)/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    backdrop-filter: blur(7.63798px);
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-height: 740px) {
        align-items: unset;
        .closeBtn {
            &::before, &::after {
                background: #000!important;
            }
        }
    }
    @media(max-width: $md5+px) {
        align-items: unset;      
        .closeBtn {
            &::before, &::after {
                background: #000!important;
            }
        } 
    }
    &Active {
        opacity: 1;
        pointer-events: visible;
        .phoneIntContClass {
            border: 1px solid red!important;
        }
    }
    .closeZone {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
    }
    .formContent {
        position: relative;
        z-index: 1010;
        width: 560px;
        background: #FFFFFF;
        padding: 40px 40px 40px 40px;
        transition: all .3s;
        .success {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 90%;
            z-index: 1020;
            opacity: 0;
            background-color: #FFF;
            pointer-events: none;
            transition: all .3s;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                mix-blend-mode: multiply;
                width: 219px;
                @media(max-width: $md4+px) {
                    width: 100px;
                }
            }
            &Active {
                pointer-events: visible;
                opacity: 1;
            }
        }
        @media(max-height: 740px) {
            overflow: auto;
        }
        @media(max-width: $md5+px) {
            overflow: auto;
            overflow-x: hidden;
            width: 100%;
        }
        @media(max-width: 700px) {
            padding: 100px 18px 50px 18px;
        }
        .closeBtn {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            right: -30px;
            cursor: pointer;
            @media(max-height: 740px) {
                right: 10px;
                top: 10px;
            }
            @media(max-width: $md5+px) {
                right: 10px;
                top: 10px;
            }
            @media(max-width: 700px) {
                top: 70px;
                right: 20px;
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                background: #fff;
                top: calc(50% - 1px);
                left: 0;
                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                background: #fff;
                top: calc(50% - 1px);
                left: 0;
                transform: rotate(-45deg);
            }
        }
        // .title {
        //     background: radial-gradient(50% 150% at 50% 50%, #5581F4 0%, #4A1F8E 61.46%, #243262 100%);
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        //     background-clip: text;
        //     text-fill-color: transparent;
        //     font-weight: 1000;
        // }
        // .subtitle {
        //     font-weight: 500;
        //     margin: 15px 0 20px 0;
        //     @media(max-width: $md6+px) {
        //         margin: 20px 0 30px 0;
        //     }
        // }
        .content {
            font-family: 'Gilroy';
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #000000;
            @media(max-width: $md4+px) {
                font-size: 18px;
            }
            @media(max-width: $md6+px) {
                font-size: 16px;
            }
            .text {
                margin: 10px 0 35px 0;
            }
            b {
                font-weight: 1000;
            }
        }
        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .dd {
                height: 50px;
                border: 1px solid #000;
                @media(max-width: $md1+px) {
                    height: 45px;
                }
                :global(.Dropdown-root) {
                    width: 100%;
                    height: 100%;
                }
                :global(.Dropdown-control) {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center!important;
                    padding: 0 27px;
                    @media(max-width: $md6+px) {
                        padding: 0 20px;
                    }
                }
                :global(.Dropdown-placeholder) {
                    color:rgb(117,117,117);
                    font-family: $gilroy;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 150%;
                    text-transform: capitalize;
                    @media(max-width: $md1+px) {
                        font-size: 18px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
                :global(.is-selected) {
                    color: #000!important;
                }
                :global(.Dropdown-arrow) {
                    top: 20px;
                }
                :global(.Dropdown-menu) {
                    font-family: $gilroy;
                    font-weight: 500;
                    font-size: 20px;
                    @media(max-width: $md1+px) {
                        font-size: 18px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px!important;
                    }
                }
                &.incorrect {
                    border-color: red!important;
                }
            }
            .input {
                font-family: $gilroy;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #000;
                border: 1px solid #000;
                padding: 0 27px;
                height: 50px;
                &.incorrect {
                    border-color: red!important;
                }
                @media(max-width: $md1+px) {
                    height: 45px;
                }
                @media(max-width: $md1+px) {
                    font-size: 18px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    padding: 0 20px;
                }
            }
            .check {
                display: flex;
                align-items: center;
                gap: 15px;
                &.incorrect {
                    .checkText {
                        color: red!important;
                    }
                }
                &Box {
                    border: 1px solid #000;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    transition: all .1s linear;
                    &Active {
                        border-width: 8px;
                    }
                }
                &Text {
                    a {
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
            .btn {
                .submit {
                    position: relative;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    background: #CF2D10;
                    border-radius: 5px;
                    padding: 13px 30px;
                    font-family: $gilroy;
                    font-weight: 800;
                    font-size: 19px;
                    line-height: 23px;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    &:hover {
                        box-shadow: 0px 0px 0px 2.5px #fff, 0px 0px 0px 5px #0C0C0C, 0px 0px 0px 10px white, 0px 0px 0px 10.5px #0C0C0C;
                        background: white;
                        color: #0C0C0C;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
    }
}